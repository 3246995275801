import _ from "lodash";
import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import get from "lodash/get";
import { Typography } from "@material-ui/core";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import Section from "../sections/Section";

const Wrapper = styled.div`
  overflow: hidden; // important
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ArticleTemplate = (props) => {
  const originLink = get(props, "location.orgin");
  const post = get(props, "data.contentfulBlogPost");
  const title = get(post, "title");
  const tags = get(post, "tags");
  const description = get(
    post,
    "childContentfulBlogPostDescriptionTextNode.description"
  );

  return (
    <Layout location={props.location}>
      <div style={{ background: "#fff" }}>
        <Helmet>
          <title>{`${title} | Diamantidou School of Music, Dance & Drama`}</title>
          {originLink && <link rel="canonical" href={`${originLink}/blog/`} />}
          {description && <meta name="description" content={description} />}
          {Array.isArray(tags) && (
            <meta name="keywords" content={tags.join(", ")} />
          )}
        </Helmet>
        <Banner
          title={post.title}
          image={_.get(post, "heroImage")}
          noMaxWidth
        />
        <Wrapper>
          <Section>
            <p
              style={{
                display: "block",
              }}
            >
              {post.publishDate}
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </Section>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      childContentfulBlogPostDescriptionTextNode {
        description
      }
      heroImage {
        file {
          url
          contentType
        }
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      tags
    }
  }
`;
