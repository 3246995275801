import _ from "lodash";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import isMediaFileVideo from "../../utils/isMediaFileVideo";

const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  max-height: 1000px;
  overflow: hidden;
`;

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 90vh;
  max-height: 1000px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.background.dark};
  background: ${({ imageUrl }) =>
    `url(${imageUrl}) no-repeat center center fixed`};
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
`;

const BannerContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BannerTitle = styled.div`
  max-width: ${({ noMaxWidth }) => (noMaxWidth ? "none" : "600px")};
  > h1 {
    color: #fff;
    font-weight: 700;
    line-height: 1.35;
    letter-spacing: 0.14rem;
    > span {
      background-color: #000;
      padding: 0.2rem 0.5rem;
    }
  }
`;

const BannerVideo = (media, children) => {
  const videoEl = useRef(null);
  useEffect(() => {
    if (_.get(videoEl, "current")) {
      videoEl.current.play();
    }
  }, [videoEl]);
  return (
    <video autoplay loop muted ref={videoEl} width="100%">
      <source
        src={_.get(media, "file.url")}
        type={_.get(media, "file.contentType")}
      />
    </video>
  );
};

const Banner = ({ title, image, imageUrl, noMaxWidth }) => {
  const MediaBanner = ({ image, children }) =>
    isMediaFileVideo(image) ? (
      <>
        <BannerVideo {...image} />
        {children}
      </>
    ) : (
      <BannerWrapper imageUrl={imageUrl ? imageUrl : _.get(image, "file.url")}>
        {children}
      </BannerWrapper>
    );
  return (
    <Wrapper>
      <MediaBanner image={image}>
        <BannerContent>
          <BannerTitle noMaxWidth>
            <Typography variant="h2" component="h1">
              <span>{title}</span>
            </Typography>
          </BannerTitle>
        </BannerContent>
      </MediaBanner>
    </Wrapper>
  );
};

export default Banner;
